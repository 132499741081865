import { Position, Toaster } from "@blueprintjs/core"

const toaster =
  typeof window !== "undefined" && window.document
    ? Toaster.create({
        className: "main-toaster",
        position: Position.TOP,
      })
    : null

export default toaster
