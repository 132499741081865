import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { H2, FormGroup, InputGroup } from "@blueprintjs/core"
import Layout from "../layouts/layout"
import AppToaster from "../utils/appToaster"
import SEO from "../components/seo"

const Account = props => {
  const [username, setUsername] = useState("")
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [confirmCode, setConfrmCode] = useState("")
  const [isCodeSent, setCodeSent] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUser(user)
      setUsername(user.username)
      setEmail(user.attributes.email)
      setPhone(user.attributes.phone_number)
    })
  }, [])

  const handleAttributeUpdate = async event => {
    try {
      event.preventDefault()

      setDisabled(true)

      if (!user) {
        throw new Error("User not defined")
      }

      await Auth.updateUserAttributes(user, { email, phone_number: phone })

      setCodeSent(true)
    } catch (err) {
      let mess = err.message

      if (mess === "Invalid phone number format.") {
        mess = `${mess} - expected country code`
      }
      AppToaster.show({
        intent: "danger",
        message: `${mess}`,
        icon: "error",
      })
    }

    setDisabled(false)
  }

  const handleConfirmCodeUpdate = async event => {
    try {
      event.preventDefault()

      setDisabled(true)

      if (!user) {
        throw new Error("User not defined")
      }

      await Auth.verifyCurrentUserAttributeSubmit("email", confirmCode)

      AppToaster.show({
        intent: "success",
        message: `Updated successfully`,
        icon: "thumbs-up",
      })

      setCodeSent(false)
    } catch (err) {
      let mess = err.message

      if (mess === "Invalid phone number format.") {
        mess = `${mess} - expected country code`
      }
      AppToaster.show({
        intent: "danger",
        message: `${mess}`,
        icon: "error",
      })
    }

    setDisabled(false)
  }

  const renderConfirmForm = (
    <form onSubmit={handleConfirmCodeUpdate}>
      <FormGroup label="confirmation code">
        <InputGroup
          value={confirmCode}
          onChange={e => setConfrmCode(e.currentTarget.value)}
          type="text"
        />
      </FormGroup>
      <button
        type="submit"
        className="default-btn w-32"
        icon="upload"
        disabled={disabled}
      >
        Update
      </button>
    </form>
  )

  const renderUpdateForm = (
    <form onSubmit={handleAttributeUpdate}>
      <FormGroup label="email">
        <InputGroup
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup label="phone number">
        <InputGroup
          value={phone}
          onChange={e => setPhone(e.currentTarget.value)}
          type="telephone"
        />
      </FormGroup>
      <button
        type="submit"
        className="default-btn w-32"
        icon="upload"
        disabled={disabled}
      >
        Update
      </button>
    </form>
  )

  return (
    <Layout>
      <SEO title="Account" />
      <div className="mx-auto w-1/2">
        <H2>{username} </H2>
        {isCodeSent ? renderConfirmForm : renderUpdateForm}
      </div>
    </Layout>
  )
}

export default Account
